import { Grid } from "@mui/material";
import React from "react";
import AffiliationProvisioning from "../../components/affiliationProvisioning/AffiliationProvisioning";
import styles from "./Partnerships.module.css";


const PartnershipsPage: React.FC = React.memo(() => {

    return (
        <div>
            <Grid container>
                <Grid className={styles.partnershipsPage_componentContainer} item lg={12} md={12} xs={12}>
                    <AffiliationProvisioning />
                </Grid>
            </Grid>
        </div >
    );
});

export default PartnershipsPage;
