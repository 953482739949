import { Button, FormHelperText, TextField } from "@mui/material";
import styles from "./DriverSearch.module.css";
import { useState } from "react";
import { Loading } from "../loading/Loading";

interface IDriverSearchProps {
    onSearchSumbit: (phoneNumber: string) => void;
    errorMessage: string | undefined;
    isLoading: boolean;
}

const DriverSearch: React.FC<IDriverSearchProps> = (props) => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const { onSearchSumbit, errorMessage, isLoading } = props;

    return (
        <div
            className={styles.root}
        >
            <div className={styles.header}>
                <TextField
                    className={styles.textBox}
                    autoFocus={true}
                    label="Enter Phone Number / UserId"
                    margin="normal"
                    variant="outlined"
                    onChange={(event) => setPhoneNumber(event.target.value)}
                    name="phoneNumber"
                    value={phoneNumber}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            onSearchSumbit(phoneNumber);
                        }
                    }}
                />
                <Button
                    className={styles.submitButton}
                    variant="contained"
                    onClick={() => onSearchSumbit(phoneNumber)}
                >
                    <Loading text="Search" isLoading={isLoading} />
                </Button>
            </div>
            <FormHelperText error={true} className={styles.errorMessage}>{`${errorMessage ?? ""}`}</FormHelperText>
        </div>
    );
}

export default DriverSearch;
