import React, { useEffect, useRef } from 'react';

interface IAdherence {
    highAdherence: number;
    lowAdherence: number;
}

const DriverAdherenceChart: React.FC<IAdherence> = (props) => {
    const { highAdherence, lowAdherence } = props;
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const canvasSize = 170;
    const center = canvasSize / 2;
    const outerRadius = center;
    const textRadius = 0.5 * center;

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const startAngle = -Math.PI / 2;
        const endAngle = (-Math.PI / 2) + 2 * Math.PI * (highAdherence / 100);

        const startAngleRemaining = endAngle;

        ctx.fillStyle = '#2D9CDB';
        ctx.beginPath();
        ctx.moveTo(center, center);
        ctx.arc(center, center, outerRadius, startAngle, endAngle, false);
        ctx.lineTo(center, center);
        ctx.fill();

        ctx.fillStyle = '#EB5757';
        ctx.beginPath();
        ctx.moveTo(center, center);
        ctx.arc(center, center, outerRadius, startAngleRemaining, 2 * Math.PI - Math.PI / 2, false);
        ctx.lineTo(center, center);
        ctx.fill();

        ctx.fillStyle = '#fff';
        ctx.font = 'bold 18px Verdana';

        const angleTravel = (startAngle + endAngle) / 2;
        const angleRemaining = (startAngleRemaining + 2 * Math.PI - Math.PI / 2) / 2;

        if (highAdherence !== 0) {
            if (highAdherence === 100) {
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.fillText(
                    '100%',
                    center,
                    center
                );
            } else {
                ctx.textAlign = 'start';
                ctx.textBaseline = 'alphabetic';
                ctx.fillText(
                    `${highAdherence.toFixed(0)}%`,
                    center + textRadius * Math.cos(angleTravel),
                    center + textRadius * Math.sin(angleTravel)
                );
            }
        }

        if ((lowAdherence) !== 0) {
            if (lowAdherence === 100) {
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.fillText(
                    '100%',
                    center,
                    center
                );
            } else {
                ctx.textAlign = 'start';
                ctx.textBaseline = 'alphabetic';
                ctx.fillText(
                    `${(lowAdherence).toFixed(0)}%`,
                    center + textRadius * Math.cos(angleRemaining),
                    center + textRadius * Math.sin(angleRemaining)
                );
            }
        }
    }, [center, highAdherence, lowAdherence, outerRadius, textRadius]);

    return (
        <div>
            <canvas ref={canvasRef} width={canvasSize} height={canvasSize}></canvas>
        </div>
    );
};

export default DriverAdherenceChart;
