import { Button, CardContent, Grid, MenuItem, Select, TextField } from "@mui/material";
import Card from "@mui/material/Card";
import { useState } from "react";
import styles from "./DriverHistoryReport.module.css";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { IDrivingHistoryReportType } from "../../api/Reports";
import { Loading } from "../loading/Loading";
import { triggerDrivingHistoryReport } from "../../api/Reports";
import { firstOfThisMonth, formatDateForBackend, lastOfThisMonth } from "../../utils/DateUtils"
import { HumsaferError } from "../../models/HumsaferError";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DriverHistoryReport: React.FC = (props) => {
    const [startDate, setStartDate] = useState(firstOfThisMonth());
    const [endDate, setEndDate] = useState(lastOfThisMonth());
    const [reportType, setReportType] = useState(IDrivingHistoryReportType.WEEKLY);
    const [mobileNumbersString, setMobileNumbersString] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [mobileNumbersError, setMobileNumbersError] = useState(false)
    const [mobileNumbers, setMobileNumbers] = useState<string[]>([]);

    const onDrivingHistoryReportRequest = async () => {

        evaluateMobileNumbersInput()
        if (mobileNumbersError) {
            return;
        }
        setIsLoading(true);
        const response = await triggerDrivingHistoryReport(
            formatDateForBackend(startDate),
            formatDateForBackend(endDate),
            reportType,
            mobileNumbers
        )

        setIsLoading(false);
        if (response instanceof HumsaferError) {
            toast.error(response.getErrorMessage())
        } else {
            toast.success("Report sent to registered email")
        }
    }

    const evaluateMobileNumbersInput = () => {
        const splitNumbers = mobileNumbersString.split("\n").join(",").split(" ").join(",").split(",");

        var errorFound = false;
        const mostlyValidNumbers: string[] = [];
        for (var possibleNumber of splitNumbers) {
            const trimmedNumber = possibleNumber.trim()
            if (trimmedNumber === "") {
                continue;
            }
            if (trimmedNumber.length < 10) {
                setMobileNumbersError(true);
                errorFound = true;
                break;
            }
            mostlyValidNumbers.push(trimmedNumber)
        }

        if (!errorFound) {
            setMobileNumbersError(false);
            setMobileNumbers(mostlyValidNumbers);
        }
    }

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Card className={styles.paymentInfo_container}>
                <CardContent>
                    <Grid container spacing={2} className={styles.paymentInfo_row}>
                        <Grid item md={3}>
                            <div className={styles.paymentInfo_label}>History Report Type</div>
                            <div className={styles.paymentInfo_label}>
                                <Select
                                    value={reportType}
                                    label="Report Type"
                                    onChange={(event) => {
                                        setReportType(IDrivingHistoryReportType[event.target.value])
                                    }}
                                >
                                    <MenuItem value={IDrivingHistoryReportType.WEEKLY}>Weekly</MenuItem>
                                    <MenuItem value={IDrivingHistoryReportType.MONTHLY}>Monthly</MenuItem>
                                    <MenuItem value={IDrivingHistoryReportType.DAILY}>Daily</MenuItem>
                                </Select>
                            </div>
                        </Grid>

                        {(reportType === IDrivingHistoryReportType.DAILY) && (
                            <Grid item md={3}>
                                <div className={styles.paymentInfo_label}>For Date</div>
                                <div className={styles.paymentInfo_label}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            renderInput={props => <TextField {...props} />}
                                            value={startDate}
                                            onChange={(date) => {
                                                if (date) {
                                                    setStartDate(date)
                                                    setEndDate(date)
                                                }
                                            }}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                        )}
                        {(reportType !== IDrivingHistoryReportType.DAILY) && (
                            <>
                                <Grid item md={3}>
                                    <div className={styles.paymentInfo_label}>Start Date</div>
                                    <div className={styles.paymentInfo_label}>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                renderInput={props => <TextField {...props} />}
                                                value={startDate}
                                                onChange={(date) => {
                                                    if (date) {
                                                        setStartDate(date)
                                                    }
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </Grid>
                                <Grid item md={3}>
                                    <div className={styles.paymentInfo_label}>End Date</div>
                                    <div className={styles.paymentInfo_label}>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                renderInput={props => <TextField {...props} />}
                                                value={endDate}
                                                onChange={(date) => {
                                                    if (date) {
                                                        setEndDate(date)
                                                    }
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </Grid>
                            </>
                        )}
                        <Grid item md={3}>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} className={styles.paymentInfo_row}>
                        <Grid item md={3}>
                            <div className={styles.paymentInfo_label}>Mobile Numbers (max 100 numbers allowed)</div>
                            <div className={styles.paymentInfo_mobileNumbersDialogBox}>
                                <TextField
                                    variant="outlined"
                                    multiline={true}
                                    rows={3}
                                    type="text"
                                    helperText={mobileNumbersError ? "Comma separated valid numbers required" : ""}
                                    error={mobileNumbersError}
                                    onFocus={() => {
                                        setMobileNumbersError(false);
                                    }}
                                    onBlur={() => {
                                        evaluateMobileNumbersInput()
                                    }}
                                    onChange={(event) => {
                                        setMobileNumbersString(event.target.value)
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} className={styles.paymentInfo_row}>
                        <Grid item md={3}>
                            <div className={styles.paymentInfo_label}>
                                <Button
                                    className={styles.submitButton}
                                    variant="contained"
                                    onClick={() => onDrivingHistoryReportRequest()}>
                                    <Loading text="Request Report" isLoading={isLoading} />
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>

            </Card>

        </>
    );
}

export default DriverHistoryReport;
