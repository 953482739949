import { Box, Button, Card, CardContent, Grid, MenuItem, TextField } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import DialogMessage, { IDialogProps } from '../../components/dialog/Dialog';
import { HumsaferThemeContext } from '../../contexts/HumsaferThemeContext';
import { HumsaferError } from '../../models/HumsaferError';
import styles from "./Shipment.module.css";
import { useForm } from 'react-hook-form';
import { Loading } from '../../components/loading/Loading';
import { useMediaQuery, Theme } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import { addShipment } from '../../api/ShipmentsApi';
import { IAddShipmentRequest, IAddress } from '../../models/Shipments';
import { getUserDetails } from '../../api/SearchApi';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Config } from "./../../utils/Config";
import moment from 'moment';
import InputAdornment from '@mui/material/InputAdornment';
import { Auth } from '../../utils/Auth';
import { CompanyDataContext } from '../../contexts/CompanyDataContext';
import { isAllowedToSwitchCompanies } from '../../utils/CapabitilityUtils';
import CompanyInfoWithSwitcher from '../../components/companyInfoWithSwitcher/CompanyInfoWithSwitcher';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { IShipment, ShipmentStatus } from '../../models/ShipmentsView';
import { changeShipmentDetailsStatus } from '../../api/ShipmentDetailsApi';
import Toast, { IToastBasicProps } from '../../components/Toast/Toast';
import { ErrorCode } from '../../models/HumsaferServerError';


interface IStart {
    startLatitude: number;
    startLongitude: number;
}
interface IDestination {
    destinationLatitude: number;
    destinationLongitude: number;
}

const mapKey = Config.getInstance().getMapKeyConfig();
const libraries: ("places")[] = ["places"];
function getAddressFromGooglePlaceResult(place: google.maps.places.PlaceResult) {
    let initialFromAddressData: Partial<IAddress> = {
        placeName: place.name,
        formattedAddress: place.formatted_address,
        latitude: place.geometry?.location?.lat() ?? 0,
        longitude: place.geometry?.location?.lng() ?? 0,
    };
    for (const addressData of (place?.address_components ?? [])) {
        if (addressData.types.includes('locality')) {
            initialFromAddressData.locality = addressData.long_name;
        }
        if (addressData.types.includes('administrative_area_level_3')) {
            initialFromAddressData.city = addressData.long_name;
        }
        if (addressData.types.includes('country')) {
            initialFromAddressData.country = addressData.long_name;
        }
        if (addressData.types.includes('postal_code')) {
            initialFromAddressData.pinCode = addressData.long_name;
        }
        if (addressData.types.includes('administrative_area_level_1')) {
            initialFromAddressData.state = addressData.long_name;
        }
    }

    return initialFromAddressData as IAddress;
}

const Shipment: React.FC = React.memo(() => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const [stepper, setStepper] = useState(1);
    const [userId, setUserId] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [estimatedEndTime, setEstimatedEndTime] = useState<Date | null>(new Date());
    const [associatedEntity, setAssociatedEntity] = useState<string>("")
    const [fromPlace, setFromPlace] = useState<IStart | null>(null);
    const [fromPlaceValue, setFromPlaceValue] = useState<string>();
    const [toPlace, setToPlace] = useState<IDestination | null>(null);
    const [toPlaceValue, setToPlaceValue] = useState<string>();
    const [placeSelectedFrom, setPlaceSelectedFrom] = useState<boolean>(true);
    const [placeSelectedTo, setPlaceSelectedTo] = useState<boolean>(true);
    const fromAutocompleteRef = useRef<google.maps.places.Autocomplete>();
    const toAutocompleteRef = useRef<google.maps.places.Autocomplete>();
    const [showDialog, setShowDialog] = useState<IDialogProps>({ open: false, message: "", type: "success" });
    const { shipmentBg } = useContext(HumsaferThemeContext);
    const [startAddress, setStartAddress] = useState<IAddress>();
    const [destinationAddress, setDestinationAddress] = useState<IAddress>();
    const mobileRef = useRef<HTMLInputElement>(null);
    const [expectedDriverFirstName, setExpectedDriverFirstName] = useState<string>("");
    const [expectedDriverLastName, setExpectedDriverLastName] = useState<string>("");
    const [expectedDriverMobileNumber, setExpectedDriverMobileNumber] = useState<string>("");
    const [nextFieldsEntered, setNextFieldsEntered] = useState(false);
    const [driverFieldsEntered, setDriverFieldsEntered] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [driverActiveShipmetnId, setDriverActiveShipmentId] = useState<string>("");
    const [driverActiveShipmentCompanyId, setDriverActiveShipmentCompanyId] = useState<string>("");
    const [formData, setFormData] = useState<IAddShipmentRequest>()
    const [showToast, setShowToast] = useState<IToastBasicProps>({
        open: false,
        message: "",
        type: "success",
    });

    const [vehicleNumber, setVehicleNumber] = useState<string>("");

    const { selectedCompany } = useContext(CompanyDataContext);

    const allowCompanySwitcher = isAllowedToSwitchCompanies();

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: mapKey,
        libraries: libraries
    });

    const handleFromPlaceSelected = () => {
        if (fromAutocompleteRef?.current) {
            const place: google.maps.places.PlaceResult | null = fromAutocompleteRef.current.getPlace();
            if (place?.geometry) {
                const address = getAddressFromGooglePlaceResult(place);
                setFromPlace({
                    startLatitude: address.latitude,
                    startLongitude: address.longitude,
                });
                setPlaceSelectedFrom(true)
                setFromPlaceValue(getValues("from"))
                setStartAddress(address);
            } else {
                setPlaceSelectedFrom(false)
            }
        }
    };

    const handleToPlaceSelected = () => {
        if (toAutocompleteRef.current) {
            const place: google.maps.places.PlaceResult | null = toAutocompleteRef.current.getPlace();
            if (place?.geometry) {
                const address = getAddressFromGooglePlaceResult(place);
                setToPlace({
                    destinationLatitude: address.latitude,
                    destinationLongitude: address.longitude,
                });
                setPlaceSelectedTo(true)
                setToPlaceValue(getValues("to"))
                setDestinationAddress(address);
            } else {
                setPlaceSelectedTo(false)
            }
        }
    };

    const { register, handleSubmit, watch, reset, setValue, getValues, trigger, formState: { errors } } = useForm<IAddShipmentRequest>();

    const Fields = watch();

    useEffect(() => {
        if (
            vehicleNumber &&
            Fields.from &&
            Fields.to &&
            associatedEntity
        ) {
            setNextFieldsEntered(true);
        } else {
            setNextFieldsEntered(false);
        }
    }, [Fields, associatedEntity, vehicleNumber]);

    useEffect(() => {
        if (expectedDriverMobileNumber && expectedDriverFirstName && expectedDriverLastName) {
            setDriverFieldsEntered(true);
        } else {
            setDriverFieldsEntered(false);
        }
    }, [expectedDriverFirstName, expectedDriverLastName, expectedDriverMobileNumber]);


    useEffect(() => {

        fromPlaceValue !== getValues("from") ? setPlaceSelectedFrom(false) : setPlaceSelectedFrom(true)
        toPlaceValue !== getValues("to") ? setPlaceSelectedTo(false) : setPlaceSelectedTo(true)

    }, [fromPlaceValue, getValues, toPlaceValue])

    const fetchUserDetails = async (mobileNumber: string) => {
        let MobileNumber = mobileNumber;
        if (!MobileNumber.startsWith("+91")) {
            MobileNumber = "+91".concat(MobileNumber);
        }

        const userInfo = await getUserDetails(MobileNumber);
        if (userInfo) {
            const { id, name, activeShipmentCompanyId, activeShipmentId } = userInfo;
            setUserId(id);
            setDriverActiveShipmentCompanyId(activeShipmentCompanyId);
            setDriverActiveShipmentId(activeShipmentId);
            if (name) {
                const [firstName, lastName] = name.split(' ');
                setExpectedDriverFirstName(firstName);
                setExpectedDriverLastName(lastName);
                setValue("expectedDriverFirstName", firstName);
                setValue("expectedDriverLastName", lastName);
            }
        }
    }

    const handleShipmentStatus = async (companyId: string, shipmentId: string, setStatus: ShipmentStatus) => {
        setIsFetching(true);
        const shipmentOrError = await changeShipmentDetailsStatus(companyId, shipmentId, setStatus);
        setIsFetching(false);
        if (shipmentOrError instanceof HumsaferError) {
            setShowToast({
                open: true,
                message: shipmentOrError.message + " Please Try Again.",
                type: "error"
            });
            handleDialogClose();
            return;
        }

        setShowToast({
            open: true,
            message: `Shipment status ${setStatus} changed successfully!`,
            type: "success"
        });
        handleDialogClose();
        if (formData) {
            onSubmit(formData);
        }
    }

    const onSubmit = async (props: IAddShipmentRequest) => {
        setFormData(props)
        let companyId = Auth.getInstance().getCompanySettings()?.id;
        if (selectedCompany) {
            companyId = selectedCompany.id;
        }

        if (companyId === undefined) {
            return
        }

        setIsSubmitting(true);
        let shipmentOrError: IShipment | HumsaferError;
        const { expectedDriverMobileNumber, ...rest } = props;
        let MobileNumber = expectedDriverMobileNumber;
        if (!MobileNumber.startsWith("+91")) {
            MobileNumber = "+91".concat(MobileNumber);
        }
        const newShipmentRequest: Partial<IAddShipmentRequest> = {
            expectedDriverMobileNumber: MobileNumber,
            ...rest,
            ...toPlace,
            ...fromPlace,
            assignedDriverUserId: userId,
            estimatedEndTime: estimatedEndTime,
            startAddress: startAddress,
            destinationAddress: destinationAddress
        };
        shipmentOrError = await addShipment(companyId, newShipmentRequest as IAddShipmentRequest);
        setIsSubmitting(false);
        if (shipmentOrError instanceof HumsaferError) {
            if (shipmentOrError.errorCode === ErrorCode.DRIVER_SHIPMENT_ALREADY_EXISTS_IN_COMPANY_EXCEPTION ||
                shipmentOrError.errorCode === ErrorCode.DRIVER_SHIPMENT_ALREADY_EXISTS_IN_DIFFERENT_COMPANY_EXCEPTION) {
                setShowDialog({
                    open: true,
                    message: shipmentOrError.message,
                    type: "error",
                    errorData: shipmentOrError.details
                })
                return;
            }
            // generic error, so show a toast
            setShowToast({
                open: true,
                message: shipmentOrError.message,
                type: "error"
            });
            return;
        }

        setShowDialog({
            open: true,
            message: `Your shipment has been successfully created!`,
            type: "success",
            shipmentData: shipmentOrError
        })
        setStepper(1);
        reset();
        setAssociatedEntity("");
        setExpectedDriverFirstName("");
        setExpectedDriverLastName("")
        setVehicleNumber("")
        setVehicleNumber("")
        setExpectedDriverMobileNumber("")
    }

    const handleNextClick = async () => {
        const isValid = await trigger();
        if (fromPlaceValue !== getValues("from")) {
            setPlaceSelectedFrom(false)
            return false
        }
        if (toPlaceValue !== getValues("to")) {
            setPlaceSelectedTo(false)
            return false
        }

        if (isValid && placeSelectedFrom && placeSelectedTo) {
            if (startAddress?.country !== destinationAddress?.country) {
                setShowToast({
                    open: true,
                    message: "From and To Addresses need to be in same country.",
                    type: "error"
                });
                return;
            }
            setStepper(2);
        }
    };

    const handleToastClose = () => {
        setShowToast({
            open: false,
            message: showToast.message,
            type: showToast.type,
        });
    };

    const handleDialogClose = () => {
        setShowDialog(prevState => ({
            ...prevState,
            open: false
        }));
    };

    const handleFromAutocompleteLoad = (autocomplete: google.maps.places.Autocomplete | any) => {
        if (typeof google !== 'undefined' && google.maps.places) {
            fromAutocompleteRef.current = autocomplete;
            autocomplete.setFields(['geometry', 'name', 'formatted_address', 'address_components']);
        }
    };

    const handleToAutocompleteLoad = (autocomplete: google.maps.places.Autocomplete) => {
        if (typeof google !== 'undefined' && google.maps.places) {
            toAutocompleteRef.current = autocomplete;
            autocomplete.setFields(['geometry', 'name', 'formatted_address', 'address_components']);
        }
    };

    let associatedEntities = Auth.getInstance().getCompanySettings()?.associatedEntities;
    if (selectedCompany) {
        associatedEntities = selectedCompany.associatedEntities;
    }
    if (associatedEntities === undefined) {
        associatedEntities = []
    }

    return (
        <>
            <Toast
                message={showToast.message}
                open={showToast.open}
                onClose={handleToastClose}
                type={showToast.type}
            />
            <DialogMessage
                open={showDialog.open}
                message={showDialog.message}
                onClose={handleDialogClose}
                type={showDialog.type}
                isLoading={isFetching}
                onEndActiveTrip={() => { handleShipmentStatus(driverActiveShipmentCompanyId, driverActiveShipmetnId, ShipmentStatus.COMPLETE) }}
                isSameCompany={selectedCompany?.id === driverActiveShipmentCompanyId ? true : false}
                shipmentData={showDialog.shipmentData}
                errorData={showDialog.errorData}
            />
            {allowCompanySwitcher && <CompanyInfoWithSwitcher />}
            <Card>
                <CardContent className={styles.shipment_cardContent}>
                    <Grid container>
                        <Grid item lg={6} md={6} xs={12}>
                            <div className={isMobile ? styles.shipment_form_content_res : styles.shipment_form_content}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Grid container spacing={1}>

                                            {stepper === 1 ? <>
                                                <Grid item xs={12} md={12}>
                                                    <div className={styles.shipment_form_heder}>Create Shipment</div>
                                                    {isMobile ? <Grid container spacing={1}>
                                                        <Grid item xs={6} >
                                                            <div className={styles.shipment_border_orange}></div>
                                                        </Grid>
                                                        <Grid item xs={6} >
                                                            <div className={styles.shipment_border_gray}> </div>
                                                        </Grid>
                                                    </Grid> : ""}
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    <TextField
                                                        {...register("vehicleNumber", {
                                                            required: "Vehicle number is required"
                                                        })}
                                                        error={errors.vehicleNumber !== undefined}
                                                        type={"text"}
                                                        label="Vehicle No*"
                                                        fullWidth
                                                        margin="normal"
                                                        variant="outlined"
                                                        size='small'
                                                        onChange={(e) => setVehicleNumber(e.target.value)}
                                                        value={vehicleNumber}

                                                    />
                                                </Grid>
                                                {isLoaded ? <>
                                                    <Grid item xs={12} md={12}>
                                                        <Autocomplete
                                                            onLoad={handleFromAutocompleteLoad}
                                                            onPlaceChanged={handleFromPlaceSelected}
                                                        >
                                                            <TextField
                                                                {...register("from", {
                                                                    required: "From required"
                                                                })}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        e.preventDefault();
                                                                        handleFromPlaceSelected();
                                                                    }
                                                                }}
                                                                error={errors.from !== undefined || !placeSelectedFrom}
                                                                type={"text"}
                                                                label="From Address*"
                                                                fullWidth
                                                                name="from"
                                                                margin="normal"
                                                                variant="outlined"
                                                                size='small'
                                                            />
                                                        </Autocomplete>
                                                    </Grid>
                                                    <Grid item xs={12} md={12}>
                                                        <Autocomplete
                                                            onLoad={handleToAutocompleteLoad}
                                                            onPlaceChanged={handleToPlaceSelected}
                                                        >
                                                            <TextField
                                                                {...register("to", {
                                                                    required: true
                                                                })}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        e.preventDefault();
                                                                        handleToPlaceSelected();
                                                                    }
                                                                }}
                                                                error={errors.to !== undefined || !placeSelectedTo}
                                                                type={"text"}
                                                                label="To Address*"
                                                                name="to"
                                                                fullWidth
                                                                margin="normal"
                                                                variant="outlined"
                                                                size='small'
                                                            />
                                                        </Autocomplete>
                                                    </Grid>
                                                </> : ""}
                                                <Grid item xs={12} md={12}>
                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                        <DatePicker
                                                            renderInput={props => <TextField {...props} label="ETA Date*"
                                                                fullWidth
                                                                margin="normal"
                                                                variant="outlined"
                                                                size='small' />}
                                                            label="ETA Date"
                                                            value={estimatedEndTime}
                                                            onChange={(newValue) => {
                                                                setEstimatedEndTime(newValue ? newValue.toDate() : moment().toDate());
                                                            }}
                                                            minDate={moment()}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    <TextField
                                                        {...register("associatedEntity", {
                                                            required: true
                                                        })}
                                                        error={errors.associatedEntity !== undefined}
                                                        label="Select Transporter*"
                                                        fullWidth
                                                        name="associatedEntity"
                                                        margin="normal"
                                                        variant="outlined"
                                                        select
                                                        size='small'
                                                        value={associatedEntity}
                                                        onChange={(e) => { setAssociatedEntity(e.target.value) }}
                                                    >
                                                        {associatedEntities.map((item) => (
                                                            <MenuItem key={item} value={item}>
                                                                {item}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    <Button
                                                        type='button'
                                                        onClick={() => handleNextClick()}
                                                        variant="contained"
                                                        className={nextFieldsEntered ? styles.shipment_next_button_filled : styles.shipment_next_button}
                                                    >
                                                        <Loading isLoading={isSubmitting} text="Next" />
                                                    </Button>
                                                </Grid>
                                            </> : <><Grid item xs={12} md={12}>
                                                <div className={styles.shipment_form_heder}>
                                                    {isMobile ? <span className={styles.shipment_back_button_res} onClick={() => setStepper(1)}> <ArrowBackIosNewIcon /> </span> :
                                                        <span className={styles.shipment_back_button} onClick={() => setStepper(1)}><ArrowBackIcon /></span>}
                                                    Driver Details</div>
                                                {isMobile ? <Grid container spacing={1}>
                                                    <Grid item xs={6}>
                                                        <div className={styles.shipment_border_orange}></div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div className={styles.shipment_border_orange}> </div>
                                                    </Grid>
                                                </Grid> : ""}
                                            </Grid>
                                                <Grid item xs={12} md={12}>
                                                    <TextField
                                                        {...register("expectedDriverMobileNumber", {
                                                            required: true,
                                                            validate: {
                                                                isValidMobile: (value) => /^\d{10}$/.test(value) || "Mobile number must be exactly 10 digits."
                                                            }
                                                        })}
                                                        onInput={() => {
                                                            const inputElement = mobileRef.current;
                                                            if (inputElement) {
                                                                const numericValue = inputElement.value.replace(/\D/g, '');
                                                                inputElement.value = numericValue;
                                                                setExpectedDriverMobileNumber(numericValue);
                                                                if (numericValue.length === 10) {
                                                                    fetchUserDetails(numericValue);
                                                                }
                                                            }
                                                        }}
                                                        inputRef={mobileRef}
                                                        error={errors.expectedDriverMobileNumber !== undefined}
                                                        type={"tel"}
                                                        label="Mobile Number*"
                                                        fullWidth
                                                        inputProps={{ maxLength: 10, minLength: 10, pattern: "\\d*", autoComplete: "nope" }}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                                                        }}
                                                        name="expectedDriverMobileNumber"
                                                        margin="normal"
                                                        variant="outlined"
                                                        value={expectedDriverMobileNumber}
                                                        size='small'

                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    <TextField
                                                        {...register("expectedDriverFirstName", {
                                                            required: true
                                                        })}
                                                        error={errors.expectedDriverFirstName !== undefined}
                                                        type={"text"}
                                                        label="Driver First Name*"
                                                        fullWidth
                                                        name="expectedDriverFirstName"
                                                        margin="normal"
                                                        variant="outlined"
                                                        size='small'
                                                        onChange={(e) => setExpectedDriverFirstName(e.target.value)}
                                                        value={expectedDriverFirstName}
                                                        inputProps={{
                                                            autoComplete: "off"
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    <TextField
                                                        {...register("expectedDriverLastName", {
                                                            required: true
                                                        })}
                                                        error={errors.expectedDriverLastName !== undefined}
                                                        type={"text"}
                                                        label="Driver Last Name*"
                                                        fullWidth
                                                        name="expectedDriverLastName"
                                                        margin="normal"
                                                        variant="outlined"
                                                        size='small'
                                                        onChange={(e) => setExpectedDriverLastName(e.target.value)}
                                                        value={expectedDriverLastName}
                                                        inputProps={{
                                                            autoComplete: "off"
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    <Button
                                                        type='submit'
                                                        disabled={isSubmitting}
                                                        variant="contained"
                                                        className={driverFieldsEntered ? styles.shipment_submit_button : styles.shipment_submit_button_gray}
                                                    >
                                                        <Loading isLoading={isSubmitting} text="Create Shipment" />
                                                    </Button>
                                                </Grid>
                                            </>

                                            }
                                        </Grid>
                                    </Box>
                                </form>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} style={{
                            backgroundImage: `url(${shipmentBg})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat"
                        }}
                        ></Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
});

export default Shipment;
