import { IShipment, ShipmentPriority } from "../models/ShipmentsView";
import { HumsaferError } from "../models/HumsaferError";
import { BACKEND_URL_V2, get, post } from "../utils/Api";

interface IGetShipments {
  shipments: IShipment[];
}

interface ICreateShipmentShareLink {
  shareLink: string;
}

interface ICreateShipmentShareLink {
  shipmentId: string,
  priority: ShipmentPriority
}

export const getShipments = async (companyId: string, date: string): Promise<IShipment[] | HumsaferError> => {
  try {
    const response = await get<IGetShipments>(`${BACKEND_URL_V2}/companies/${companyId}/shipments?shipmentsStartingFrom=${date}`);
    if (response.parsedBody?.shipments) {
      return response.parsedBody.shipments;
    }
    const errorMessage = response.serverError?.error.message ?? "Shipment could not be retrieved";
    return new HumsaferError(errorMessage);
  } catch (err) {
    return new HumsaferError("Something went wrong");
  }
};


export const createShipmentShareLink = async (companyId: string, shipmentId: string): Promise<ICreateShipmentShareLink | HumsaferError> => {
  try {
    const response = await post<ICreateShipmentShareLink>(`${BACKEND_URL_V2}/companies/${companyId}/shipments/${shipmentId}/shareLink`);
    if (response.parsedBody) {
      return response.parsedBody;
    }
    const errorMessage = response.serverError?.error.message ?? "Something went wrong";
    return new HumsaferError(errorMessage);
  } catch (err) {
    return new HumsaferError("Something went wrong");
  }
};

export const changeShipmentPriority = async (companyId: string, shipmentId: string, priority: ShipmentPriority): Promise<ICreateShipmentShareLink | HumsaferError> => {
  try {

    const response = await post<ICreateShipmentShareLink>(`${BACKEND_URL_V2}/companies/${companyId}/shipments/${shipmentId}/priority/${priority}`);
    if (response.parsedBody) {
      return response.parsedBody;
    }
    const errorMessage = response.serverError?.error.message ?? "Something went wrong";
    return new HumsaferError(errorMessage);
  } catch (err) {
    return new HumsaferError("Something went wrong");
  }
};

