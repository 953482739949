export interface IShipment {
  id: string;
  companyId: string;
  createdByUserId: string;
  creationTime: number;
  startTime: number;
  dispatchTime: Date;
  estimatedEndTime: Date;
  actualEndTime: Date;
  associatedEntity: string;
  associatedLocation: string;
  status: ShipmentStatus;
  vehicleNumber: string;
  driverAssignmentStatus: DriverAssignmentStatus;
  shipmentTrackingStatus: ShipmentTrackingStatus;
  driverName: string;
  driverMobileNumber: string;
  priority: ShipmentPriority;
  shipmentStartCity: string;
  shipmentDestinationCity: string;
  vanityId: string;
  shipmentCompletionPercentage: number;
  lastLiveLocationReceivedTime: number;
  appHealth: AppHealth;
  isShipmentActivelyTracked: boolean;
  shipmentEndMethod: IShipmentEndMethod;
  loadPLantCode?: string;
  loadPlantDescription?: string;
}

export enum IShipmentEndMethod {
  AUTO = "AUTO",
  MANUAL = "MANUAL"
}

export enum ShipmentTrackingStatus {
  ACTIVELY_TRACKED = "ACTIVELY_TRACKED",
  INTERMITTENTLY_TRACKED = "INTERMITTENTLY_TRACKED",
  MISSING_TRACKING_INFO = "MISSING_TRACKING_INFO"
}


export enum AppHealth {
  HEALTHY = "HEALTHY",
  PERMISSIONS_PENDING = "PERMISSIONS_PENDING",
  APP_NOT_INSTALLED = "APP_NOT_INSTALLED",
  APP_UPDATE_REQUIRED = "APP_UPDATE_REQUIRED"
}

export enum ShipmentStatus {
  CREATED = "CREATED",
  IN_TRANSIT = "IN_TRANSIT",
  COMPLETE = "COMPLETE",
  CANCELLED = "CANCELLED",
}

export enum DriverAssignmentStatus {
  VERIFIED_DRIVER_ASSIGNED = "VERIFIED_DRIVER_ASSIGNED",
  DRIVER_INFO_PROVIDED = "DRIVER_INFO_PROVIDED",
  COMPLETE = "COMPLETE",
  CANCELLED = "CANCELLED",
}

export enum IDateRange {
  TODAY = "TODAY",
  LAST_WEEK = "LAST_WEEK",
  LAST_MONTH = "LAST_MONTH"
}

export enum IFilterStatus {
  ALL = "ALL",
  APP_STATUS_UNHEALTHY = "APP_STATUS_UNHEALTHY",
  IN_TRANSIT = "IN_TRANSIT",
  UNTRACKED = "UNTRACKED",
  COMPLETED = "COMPLETED",
  PRIORITIZED = "PRIORITIZED",
}

export enum ShipmentPriority {
  PRIORITIZED = "PRIORITIZED",
  DEFAULT = "DEFAULT"
}
