import React from 'react';

const HomePage: React.FC = React.memo(() => {
    return (
        <div>
            <h1> Home Page - Logged in</h1>
        </div>
    );
});

export default HomePage;
