import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useContext, useState } from "react";
import styles from "./ShipmentReportsPage.module.css";
import { HumsaferThemeContext } from "../../contexts/HumsaferThemeContext";
import ShipmentReport from "../../components/shipmentListReport/ShipmentLIstReport";

enum ReportType {
    EXPORT_SHIPMENTS = "EXPORT_SHIPMENTS",
}

const ShipmentReportsPage: React.FC = React.memo(() => {

    const { primaryColor } = useContext(HumsaferThemeContext);

    const [selectedReportType, setSelectedReportType] = useState(ReportType.EXPORT_SHIPMENTS);

    return (
        <Box>
            <Grid container>
                <Grid item lg={12} md={12} xs={12}>
                    <Box className={styles.shipmentReportsPage_parentContainer}>
                        <Box className={styles.title} style={{ color: primaryColor }}>
                            Shipment Reports
                        </Box>
                        <FormControl>
                            <InputLabel id="report-type-select-label">Report Type</InputLabel>
                            <Select
                                labelId="report-type-select-label"
                                id="report-type-select"
                                value={selectedReportType}
                                label="Report Type"
                                onChange={(e) => setSelectedReportType(e.target.value as ReportType)}
                            >
                                <MenuItem value={ReportType.EXPORT_SHIPMENTS}>{"Export Shipments"}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Grid className={styles.shipmentReportsPage_componentContainer} item lg={12} md={12} xs={12}>
                        {selectedReportType === ReportType.EXPORT_SHIPMENTS ? (<ShipmentReport />) : null}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
});

export default ShipmentReportsPage;
