import { Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { getUserInfo } from "../../api/Drivers";
import BiradareeInfo from "../../components/biradareeInfo/BiradareeInfo";
import DriverProfileInfo from "../../components/driverProfileInfo/DriverProfileInfo";
import DriverSearch from "../../components/driverSearch/DriverSearch";
import DrivingLicenseInfo from "../../components/drivingLicenseInfo/DrivingLicenseInfo";
import PaymentInfo from "../../components/paymentInfo/PaymentInfo";
import RidesTable from "../../components/ridesTable/RidesTable";
import Toast, { IToastBasicProps } from "../../components/Toast/Toast";
import { IGetUserInfoResponse } from "../../models/GetUserInfoResponse";
import { HumsaferError } from "../../models/HumsaferError";
import styles from "./DriverSupportPage.module.css";
import DevicesTable from "../../components/devicesTable/DevicesTable";
import LiveLocationTable from "../../components/liveLocationTable/LiveLocationTable";
import DriverShipmentsTable from "../../components/driverShipmentsTable/DriverShipmentsTable";
import { LocalCache } from "../../utils/LocalCache";
import { getCompanies } from "../../api/Companies";
import { CompanyDataContext } from "../../contexts/CompanyDataContext";
import { ICompany } from "../../models/Companies";

const DriverSupportPage: React.FC = React.memo(() => {
    const [driverProfile, setDriverProfile] = useState<IGetUserInfoResponse>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [isLoading, setIsLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState<string>();
    const [companies, setCompanies] = useState<ICompany[]>([]);

    const { selectCompany } = useContext(CompanyDataContext);

    const [showToast, setShowToast] = useState<IToastBasicProps>({
        open: false,
        message: "",
        type: "success",
    });

    const handleToastClose = () => {
        setShowToast({
            open: false,
            message: showToast.message,
            type: showToast.type,
        });
    };

    const fetchCompanies = async () => {
        setIsLoading(true);
        const companiesOrError = await getCompanies();
        setIsLoading(false);
        if (companiesOrError instanceof HumsaferError) {
            setShowToast({
                open: true,
                message: companiesOrError.message,
                type: "error"
            })
            return;
        }

        LocalCache.saveCompanyList(companiesOrError);
    };

    const requestCompanySwitch = async (companyId: string, onCompanySwitchSuccess: () => void) => {
        const companyExists = companies?.find(company => company.id === companyId);
        if (!companyExists) {
            await fetchCompanies();
            const newCompanies = LocalCache.getCompanyList();
            const foundCompany = newCompanies?.find(company => company.id === companyId);
            if(foundCompany) {
                selectCompany(foundCompany);
            }
        } else {
            selectCompany(companyExists);
        }
        onCompanySwitchSuccess();
    };

    useEffect(() => {
        const cachedCompanyList = LocalCache.getCompanyList();
        if (!cachedCompanyList) {
            fetchCompanies();
            return;
        } 
        setCompanies(cachedCompanyList);
    }, []);

    const onSearchProfile = async (phoneNumber: string) => {
        setDriverProfile(undefined);
        setIsLoading(true);
        setErrorMessage(undefined)
        setPhoneNumber(phoneNumber)
        const userInfo = await getUserInfo(phoneNumber)
        setIsLoading(false);
        if (userInfo instanceof HumsaferError) {
            setErrorMessage(userInfo.getErrorMessage())
        } else {
            setDriverProfile(userInfo);
        }
    }

    return (
        <div>
            <Toast
                message={showToast.message}
                open={showToast.open}
                onClose={handleToastClose}
                type={showToast.type}
            />
            <DriverSearch
                onSearchSumbit={onSearchProfile}
                isLoading={isLoading}
                errorMessage={errorMessage}
            />
            {driverProfile && (
                <Grid container>
                    <Grid className={styles.driverSupport_componentContainer} item lg={12} md={12} xs={12}>
                        <DriverProfileInfo
                            driverProfile={driverProfile.userProfile}
                            lastPointsEarnedTime={driverProfile.lastStarPointEarnedTime}
                            lastRedeemTime={driverProfile.lastRedemptionEventTime}
                            lastRideEndTime={driverProfile.lastRideEndTime}
                            userRewardsView={driverProfile.userRewardsView}
                            userPerformanceView={driverProfile.userPerformanceView}
                        />
                    </Grid>
                    <Grid className={styles.driverSupport_componentContainer} item lg={12} md={12} xs={12}>
                        <DevicesTable devices={Object.values(driverProfile.devices ? driverProfile.devices : [])} driverProfile={driverProfile.userProfile} />
                    </Grid>

                    <Grid className={styles.driverSupport_componentContainer} item lg={12} md={12} xs={12}>
                        <LiveLocationTable lastLiveLocations={Object.values(driverProfile.lastLiveLocations)} />
                    </Grid>

                    <Grid className={styles.driverSupport_componentContainer} item lg={12} md={12} xs={12}>
                        <RidesTable rides={driverProfile.recentRides} />
                    </Grid>

                    <Grid className={styles.driverSupport_componentContainer} item lg={12} md={12} xs={12}>
                        <DriverShipmentsTable 
                            shipments={driverProfile.recentShipments}
                            requestCompanySwitch={requestCompanySwitch}
                        />
                    </Grid>

                    <Grid className={styles.driverSupport_componentContainer} item lg={12} md={12} xs={12}>
                        <DrivingLicenseInfo
                            drivingLicense={driverProfile.drivingLicenseView}
                            allowVerification={true}
                            refreshDL={(successMessage) => {
                                if (successMessage) {
                                    setShowToast({
                                        open: true,
                                        message: successMessage,
                                        type: "success"
                                    });
                                }

                                if (phoneNumber) {
                                    onSearchProfile(phoneNumber);
                                }
                            }} />
                    </Grid>

                    <Grid className={styles.driverSupport_componentContainer} item lg={12} md={12} xs={12}>
                        <PaymentInfo paymentMethods={driverProfile.paymentMethods} payoutTransactions={driverProfile.payoutTransactions} />
                    </Grid>

                    <Grid className={styles.driverSupport_componentContainer} item lg={12} md={12} xs={12}>
                        <BiradareeInfo biradareeInsuranceView={driverProfile.biradareeInsuranceView} />
                    </Grid>
                </Grid>
            )}
        </div>
    );
});

export default DriverSupportPage;
